<template>
  <div class="card">
    <card-loader v-if="showLoading" />
    <template v-else>
      <div class="header-container">
        <div class="icon" v-bind:class="theme">
          <slot></slot>
        </div>
        <div v-if="showIconInfo"
          class="icon"
          @click="$emit('info-clicked')"
          v-bind:class="'info'"
          v-tooltip="information">
          <information-variant-icon/>
        </div>
      </div>
      <div class="content" v-bind:class="{ red: showAsWarning }">
        <span v-text="displayValue"></span>
      </div>
      <div class="title">
        <button
          class="action"
          type="button"
          v-if="showDownloadButton"
          v-on:click="$emit('download')"
        >
          <download-icon />
        </button>
        <div v-text="$t(name)"></div>
        <slot name="description"></slot>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
    CardLoader: () => import('@/components/loaders/Card.vue'),
  },
  props: {
    cardId: {
      required: true,
      type: String,
    },
    theme: {
      type: String,
      default: '',
    },
    name: {
      type: String,
    },
    value: {
      required: true,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    showAsWarning: {
      type: Boolean,
      default: false,
    },
    showDownloadButton: {
      type: Boolean,
      default: false,
    },
    showIconInfo: String,
  },
  computed: {
    ...mapState({
      loader: state => state.dashboard.loader || {},
    }),
    showLoading() {
      return this.loader[this.cardId];
    },
    displayValue() {
      return `${this.prefix}${this.value}${this.suffix}`;
    },
    information() {
      return this.$t(this.showIconInfo);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_data-card.scss";
</style>
